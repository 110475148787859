export const voivodeshipList = [
  { value: "dolnoslaskie", label: "Dolnośląskie" },
  { value: "kujawskoPomorskie", label: "Kujawsko-Pomorskie" },
  { value: "lubelskie", label: "Lubelskie" },
  { value: "lubuskie", label: "Lubuskie" },
  { value: "lodzkie", label: "Łódzkie" },
  { value: "malopolskie", label: "Małopolskie" },
  { value: "mazowieckie", label: "Mazowieckie" },
  { value: "opolskie", label: "Opolskie" },
  { value: "podkarpackie", label: "Podkarpackie" },
  { value: "podlaskie", label: "Podlaskie" },
  { value: "pomorskie", label: "Pomorskie" },
  { value: "slaskie", label: "Śląskie" },
  { value: "swietokrzyskie", label: "Świętokrzyskie" },
  { value: "warminskoMazurskie", label: "Warmińsko-Mazurskie" },
  { value: "wielkopolskie", label: "Wielkopolskie" },
  { value: "zachodniopomorskie", label: "Zachodniopomorskie" },
];
