import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Footer from "./Footer";

const ResetPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Niepoprawny adres email")
        .required("Adres email jest wymagany"),
    }),
    onSubmit: async () => {
      try {
        await sendPasswordResetEmail(auth, formik.values.email);
        alert("Sprawdź swoją skrzynkę pocztową");
        history("/signin");
      } catch (err) {
        console.log(err);
      }
    },
  });

  const history = useNavigate();
  const auth = getAuth();

  return (
    <div className="resetPassword">
      <div>
        <div className="content-div">
          <div className="form-div">
            <form onSubmit={formik.handleSubmit} className="shadow-md">
              <h1 className="border-silver">Zresetuj hasło</h1>
              <div className="input-div">
                <label className="text-gray-700">Adres email</label>
                <input
                  aria-label="Username"
                  className="focus:shadow-outline w-full appearance-none rounded border leading-tight text-gray-700 shadow focus:outline-none"
                  id="username"
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-xs italic text-red-500">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>
              <div>
                <button
                  aria-label="Reset"
                  className="focus:shadow-outline rounded bg-blue-500 font-bold text-white hover:bg-blue-700 focus:outline-none"
                  type="submit">
                  Zresetuj
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ResetPassword;
