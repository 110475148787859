const Footer = () => {
  return (
    <div className="footer">
      <p className="footer__bottom-text">
        &copy; <span className="footer__year"></span>2024 Mateusz Będkowski
      </p>
    </div>
  );
};

export default Footer;
