export const fishList = [
  { value: "amur", label: "Amur" },
  { value: "bolen", label: "Boleń" },
  { value: "jaz", label: "Jaź" },
  { value: "karas", label: "Karaś" },
  { value: "karp", label: "Karp" },
  { value: "kielb", label: "Kiełb" },
  { value: "klen", label: "Kleń" },
  { value: "krap", label: "Krąp" },
  { value: "leszcz", label: "Leszcz" },
  { value: "lin", label: "Lin" },
  { value: "lipien", label: "Lipień" },
  { value: "mietus", label: "Miętus" },
  { value: "okon", label: "Okoń" },
  { value: "pstragPotokowy", label: "Pstrąg potokowy" },
  { value: "pstragTeczowy", label: "Pstrąg tęczowy" },
  { value: "ploc", label: "Płoć" },
  { value: "sandacz", label: "Sandacz" },
  { value: "sum", label: "Sum" },
  { value: "szczupak", label: "Szczupak" },
  { value: "trocJeziorowa", label: "Troć jeziorowa" },
  { value: "ukleja", label: "Ukleja" },
  { value: "wzdrega", label: "Wzdręga" },
  { value: "wegorz", label: "Węgorz" },
  { value: "inne", label: "Inne" },
];
